<template>
  <v-sheet flat>
    <v-card flat class="ma-1">
      <v-card-text class="px-10">
        <v-form lazy-validation ref="form">
          <v-row dense class="pa-0  mt-4 mx-0">
            <v-col cols="12" md="6">
              <span class="subtitle-1 mb-0">DUT Name *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="dutFieldDescriptions.dut_name"></span>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <v-text-field
                name="dut1Reference"
                type="text"
                autocomplete="off"
                v-model="formDut1Reference"
                outlined
                dense
                class="subtitle-1"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="dutname"
                :data-cy="`rfq-dut-dut-name-${dutIndex}`"
                @input="
                  updateUIState({
                    key: `dut_name_${dutIndex}`,
                    value: formDut1Reference,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formDut1Reference,
                    vuexValue: dutDetails.dut_name,
                  })
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-sheet
                rounded
                light
                outlined
                class="px-5"
                :class="invalidManufacturing ? 'borderError' : 'currentColor'"
              >
                <v-row dense class="pa-0 mt-5 mx-0">
                  <v-col cols="12">
                    <span class="subtitle-1">Manufacturing Files *</span>
                  </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-0">
                  <v-col cols="12">
                    <v-alert
                      v-if="invalidManufacturing"
                      color="error"
                      dense
                      outlined
                      type="info"
                      class="ma-0 subtitle-2"
                      >Upload a zipped folder of manufacturing files. Gerbers,
                      ODB++, or IPC2581.</v-alert
                    >
                  </v-col>
                </v-row>
                <v-row dense class="pa-0 mt-5 mx-0">
                  <v-col cols="12">
                    <span class="subtitle-1">Gerber Zip</span>
                  </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-0">
                  <v-col cols="12">
                    <span v-html="dutFieldDescriptions.gerber_zip"></span>
                  </v-col>
                </v-row>
                <v-row dense class="mb-10">
                  <v-col cols="12">
                    <div>
                      <GerberFileChip
                        v-if="
                          unmodifiedGerberZipContainer &&
                            zippedGerbers.length === 0
                        "
                        :editMode="editMode"
                        :fileList="unmodifiedGerberZipContainer"
                        prefixText="Uploaded Gerber Zip"
                        :allowDelete="false"
                        :allowDownload="true"
                        :allowDeleteAll="false"
                        @download="downloadFile"
                        color="secondary"
                        class="ml-3"
                      />
                    </div>
                    <GerberFileChip
                      v-if="gerberZipContainer && zippedGerbers.length === 0"
                      :editMode="editMode"
                      :fileList="gerberZipContainer"
                      prefixText="Saved Layer File"
                      removeAllText="Clear"
                      :allowDelete="false"
                      :allowDownload="true"
                      :allowDeleteAll="
                        (isQuoteIncomplete || isAdmin) && (calledFromQuote ? Object.keys(quote.fixture_design_details).length > 0 : true) ? true : false
                      "
                      @download="downloadFile"
                      @removeAll="
                        showDialog({
                          nextAction: `removeConfirmed`,
                          message: `Are you sure you want to remove the Gerber files and data associated with it?`,
                        })
                      "
                      class="ml-3"
                    />
                    <v-chip
                      v-if="
                        zippedGerberContainer &&
                          zippedGerbers.length === 0 &&
                          gerberZipContainer
                      "
                      class="ma-2"
                      color="warning"
                      text-color="white"
                      small
                      @click="downloadFile(zippedGerberContainer[0])"
                    >
                      <v-icon left>
                        mdi-alert-circle-outline
                      </v-icon>
                      {{ `Download All Gerbers` }}
                    </v-chip>
                    <v-alert
                      v-if="
                        calledFromQuote &&
                          Object.keys(quote.fixture_design_details).length == 0
                      "
                      border="left"
                      color="red"
                      type="info"
                      class="subtitle-2 px-5"
                    >
                      This Quote's Project was unlinked from it. Re-upload the
                      Quote's Gerber zip file to re-parse gerber layers and save
                      them to a new Fixture Design and DUT Config.
                    </v-alert>
                    <v-sheet
                      outlined
                      rounded
                      class="ml-8 cursor-text py-2"
                      :class="
                        !isQuoteIncomplete && !isAdmin
                          ? 'disabledColor'
                          : 'currentColor'
                      "
                      @click.stop="openGerberModal"
                      :data-cy="`rfq-dut-open-gerber-modal-${dutIndex}`"
                    >
                      <v-row>
                        <v-col class="d-flex">
                          <v-icon class="ml-n8">mdi-paperclip</v-icon>
                          <span
                            v-if="zippedGerbers.length === 0"
                            class="ml-5 subtitle-1"
                            :class="
                              (!isInitialState && !isAdmin) ||
                              (!isQuoteIncomplete && !isAdmin)
                                ? 'grey--text'
                                : ''
                            "
                          >
                            Click here to upload zipped Gerber Files *
                          </span>
                          <span v-else>
                            <GerberFileChip
                              :editMode="editMode"
                              :fileList="[zippedGerbers[0]]"
                              class="ml-5"
                            />
                            <span
                              v-if="zippedGerbers.length > 1"
                              class="subtitle-2 grey--text text--darken-3 mx-2"
                            >
                              +{{ zippedGerbers.length - 1 }} File(s)
                            </span>
                            <span>
                              <v-btn
                                small
                                color="warning"
                                @click.stop="clearUploadedGerbers"
                                >Clear</v-btn
                              ></span
                            >
                          </span>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </v-row>
                <v-row dense class="pa-0 mt-5 mx-0">
                  <v-col cols="12">
                    <span class="subtitle-1">ODB++ Zip</span>
                  </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-0">
                  <v-col cols="12">
                    <span v-html="dutFieldDescriptions.odb_zip"></span>
                  </v-col>
                </v-row>
                <v-row dense class="mb-10">
                  <v-col cols="12">
                    <GerberFileChip
                      v-if="odbZipContainer"
                      :fileList="odbZipContainer"
                      removeAllText="Clear"
                      :allowDelete="false"
                      :allowDownload="true"
                      :allowDeleteAll="false"
                      @download="downloadFile"
                    />
                    <v-file-input
                      show-size
                      counter
                      v-model="formDut1OdbZip"
                      accept=".zip, .rar, .tgz"
                      hint="File types: .zip, .rar, .tgz"
                      persistent-hint
                      outlined
                      dense
                      :disabled="!isQuoteIncomplete && !isAdmin"
                      data-test="odbzipfile"
                      :data-cy="`rfq-dut-odb-file-input-${dutIndex}`"
                      @change="
                        updateUIState({
                          key: `odb_zip_${dutIndex}`,
                          value: formDut1OdbZip,
                        })
                      "
                      :rules="
                        hybridRules({
                          required: false,
                          formValue: formDut1OdbZip,
                          vuexValue: null,
                        })
                      "
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row dense class="pa-0 mt-5 mx-0">
                  <v-col cols="12">
                    <span class="subtitle-1">IPC2581 Zip</span>
                  </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-0">
                  <v-col cols="12">
                    <span v-html="dutFieldDescriptions.ipc_zip"></span>
                  </v-col>
                </v-row>
                <v-row dense class="mb-10">
                  <v-col cols="12">
                    <GerberFileChip
                      v-if="ipcZipContainer"
                      :fileList="ipcZipContainer"
                      removeAllText="Clear"
                      :allowDelete="false"
                      :allowDownload="true"
                      :allowDeleteAll="false"
                      @download="downloadFile"
                    />
                    <v-file-input
                      show-size
                      counter
                      v-model="formDut1IpcZip"
                      accept=".zip, .rar, .tgz"
                      hint="File types: .zip, .rar, .tgz"
                      persistent-hint
                      outlined
                      dense
                      :disabled="!isQuoteIncomplete && !isAdmin"
                      data-test="ipczipfiles"
                      :data-cy="`rfq-dut-ipc-file-input-${dutIndex}`"
                      @change="
                        updateUIState({
                          key: `ipc_zip_${dutIndex}`,
                          value: formDut1IpcZip,
                        })
                      "
                      :rules="
                        hybridRules({
                          required: false,
                          formValue: formDut1IpcZip,
                          vuexValue: null,
                        })
                      "
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-sheet
                rounded
                light
                outlined
                class="px-5 pb-5 my-5"
                :class="
                  !formDut1TestPoint && !testpointFileContainer
                    ? 'borderError'
                    : 'currentColor'
                "
              >
                <v-row dense class="pa-0 mt-5 mx-0">
                  <v-col cols="12">
                    <span class="subtitle-1"
                      >Device Under Test (DUT) Report *</span
                    >
                  </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-0">
                  <v-col cols="12">
                    <span v-html="dutFieldDescriptions.test_point_file"></span>
                  </v-col>
                </v-row>
                <v-row dense class="mb-10">
                  <v-col cols="12">
                    <GerberFileChip
                      v-if="testpointFileContainer"
                      :fileList="testpointFileContainer"
                      removeAllText="Clear"
                      :allowDelete="false"
                      :allowDownload="true"
                      :allowDeleteAll="
                        isQuoteIncomplete || isAdmin ? true : false
                      "
                      @download="downloadFile"
                      @removeAll="
                        showDialog({
                          nextAction: `removeDutConfirmed`,
                          message: `Are you sure you want to remove the DUT File and data associated with it?`,
                        })
                      "
                    />
                    <v-sheet
                      outlined
                      rounded
                      class="ml-8 py-2 cursor-text"
                      :class="
                        !testpointFileContainer && !formDut1TestPoint
                          ? 'borderError'
                          : !isQuoteIncomplete && !isAdmin
                          ? 'disabledColor'
                          : 'currentColor'
                      "
                      @click.stop="openParserModal"
                      :data-cy="`rfq-dut-open-dut-modal-${dutIndex}`"
                    >
                      <v-row>
                        <v-col class="d-flex">
                          <v-icon
                            class="ml-n8"
                            :color="
                              !testpointFileContainer && !formDut1TestPoint
                                ? 'error'
                                : ''
                            "
                            >mdi-paperclip</v-icon
                          >
                          <span
                            v-if="!formDut1TestPoint"
                            class="ml-5 subtitle-1"
                            :class="
                              !testpointFileContainer && !formDut1TestPoint
                                ? 'error--text'
                                : (!isInitialState && !isAdmin) ||
                                  (!isQuoteIncomplete && !isAdmin)
                                ? 'grey--text'
                                : ''
                            "
                          >
                            Click here to upload Test Point file *
                          </span>
                          <span v-else>
                            <!-- <GerberFileChip
                              v-if="testpointFileContainer"
                              :fileList="testpointFileContainer"
                            /> -->
                            <GerberFileChip
                              prefixText="Uploaded File"
                              :editMode="editMode"
                              :fileList="[{ file: formDut1TestPoint.name }]"
                              :allowDelete="false"
                              :allowDownload="false"
                              :allowDeleteAll="false"
                              @removeAll="removeAllZip"
                              class="ml-5"
                            />
                            <span>
                              <v-btn
                                small
                                color="warning"
                                @click.stop="clearUploadedTestPoint"
                                >Clear</v-btn
                              ></span
                            >
                          </span>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </v-row>
                <v-row
                  v-if="!testpointFileContainer && !formDut1TestPoint"
                  dense
                  class="pa-0 mt-5 mx-0"
                >
                  <v-col cols="12">
                    <span class="subtitle-1"
                      >DUT dimensions will be populated after DUT File is
                      uploaded.</span
                    >
                  </v-col>
                </v-row>
                <v-row dense class="pa-0 mt-2 mx-0">
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="12" md="4">
                        <v-row dense>
                          <v-col cols="12">
                            <span
                              class="subtitle-1"
                              :class="!formDut1TestPoint ? 'grey--text' : ''"
                              >PCB Width</span
                            >
                          </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-0">
                          <v-col cols="12">
                            <span
                              :class="!formDut1TestPoint ? 'grey--text' : ''"
                              >{{ pcbUnit }}</span
                            >
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              name="dut1PcbWidth"
                              type="number"
                              v-model="formDut1PcbWidth"
                              outlined
                              dense
                              class="subtitle-1"
                              data-test="pcbwidth"
                              disabled
                              :onInput="onInputString()"
                              @input="
                                updateUIState({
                                  key: `pcb_width_${dutIndex}`,
                                  value: formDut1PcbWidth,
                                })
                              "
                              :rules="
                                hybridRules({
                                  required: false,
                                  formValue: formDut1PcbWidth,
                                  vuexValue: dutDetails.pcb_width,
                                })
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-row dense>
                          <v-col cols="12">
                            <span
                              class="subtitle-1"
                              :class="!formDut1TestPoint ? 'grey--text' : ''"
                              >PCB Height</span
                            >
                          </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-0">
                          <v-col cols="12">
                            <span
                              :class="!formDut1TestPoint ? 'grey--text' : ''"
                              >{{ pcbUnit }}</span
                            >
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              name="dut1PcbHeight"
                              type="number"
                              v-model="formDut1PcbHeight"
                              outlined
                              dense
                              class="subtitle-1"
                              data-test="pcbheight"
                              :onInput="onInputString()"
                              disabled
                              @input="
                                updateUIState({
                                  key: `pcb_height_${dutIndex}`,
                                  value: formDut1PcbHeight,
                                })
                              "
                              :rules="
                                hybridRules({
                                  required: false,
                                  formValue: formDut1PcbHeight,
                                  vuexValue: dutDetails.pcb_height,
                                })
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-row dense>
                          <v-col cols="12">
                            <span
                              class="subtitle-1"
                              :class="!formDut1TestPoint ? 'grey--text' : ''"
                              >PCB Thickness</span
                            >
                          </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-0">
                          <v-col cols="12">
                            <span
                              :class="!formDut1TestPoint ? 'grey--text' : ''"
                              >{{ pcbUnit }}</span
                            >
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              name="dut1PcbThickness"
                              type="number"
                              v-model="formDut1PcbThickness"
                              outlined
                              dense
                              class="subtitle-1"
                              data-test="pcbthickness"
                              disabled
                              :onInput="onInputString()"
                              @input="
                                updateUIState({
                                  key: `pcb_thickness_${dutIndex}`,
                                  value: formDut1PcbThickness,
                                })
                              "
                              :rules="
                                hybridRules({
                                  required: false,
                                  formValue: formDut1PcbThickness,
                                  vuexValue: dutDetails.pcb_thickness,
                                })
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-5 mx-0">
            <v-col cols="12">
              <span class="subtitle-1">PCBA 3D Model *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="dutFieldDescriptions.step_file"></span>
            </v-col>
          </v-row>
          <v-row dense class="mb-10">
            <v-col cols="12" md="6">
              <GerberFileChip
                v-if="stepFileContainer"
                :fileList="stepFileContainer"
                removeAllText="Clear"
                :allowDelete="false"
                :allowDownload="true"
                :allowDeleteAll="false"
                @download="downloadFile"
              />
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-file-input
                      show-size
                      counter
                      v-model="formDut1Model3D"
                      accept=".step, .stp"
                      hint="File types: .step, .stp"
                      persistent-hint
                      outlined
                      dense
                      :disabled="!isQuoteIncomplete && !isAdmin"
                      data-test="stepfile"
                      :data-cy="`rfq-dut-step-file-input-${dutIndex}`"
                      @change="
                        updateUIState({
                          key: `step_file_${dutIndex}`,
                          value: formDut1Model3D,
                        })
                      "
                      :rules="
                        hybridRules({
                          required: !stepFileContainer,
                          formValue: formDut1Model3D,
                          vuexValue: null,
                        })
                      "
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </span>
                </template>
                <span>Accepted file types: .step, .stp</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-10 mx-0">
            <v-col cols="12">
              <span class="subtitle-1"
                >PCB Schematic
                {{
                  quote.test_instrumentation_selection_and_integration ||
                  quote.test_software_development
                    ? "*"
                    : ""
                }}</span
              >
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="dutFieldDescriptions.schematic_file"></span>
            </v-col>
          </v-row>
          <v-row dense class="mb-10">
            <v-col cols="12" md="6">
              <GerberFileChip
                v-if="schematicFileContainer"
                :fileList="schematicFileContainer"
                removeAllText="Clear"
                :allowDelete="false"
                :allowDownload="true"
                :allowDeleteAll="false"
                @download="downloadFile"
              />
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-file-input
                      show-size
                      counter
                      v-model="formDut1Schematic"
                      accept=".pdf"
                      hint="File type: .pdf"
                      persistent-hint
                      outlined
                      dense
                      :disabled="!isQuoteIncomplete && !isAdmin"
                      data-test="schematicfile"
                      :data-cy="`rfq-dut-schematic-file-input-${dutIndex}`"
                      @change="
                        updateUIState({
                          key: `schematic_file_${dutIndex}`,
                          value: formDut1Schematic,
                        })
                      "
                      :rules="
                        hybridRules({
                          required:
                            (quote.test_instrumentation_selection_and_integration ||
                              quote.test_software_development) &&
                            !schematicFileContainer,
                          formValue: formDut1Schematic,
                          vuexValue: null,
                        })
                      "
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </span>
                </template>
                <span>Accepted file types: .pdf</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-5 mx-0">
            <v-col cols="12">
              <span class="subtitle-1">Other Supporting Files</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="dutFieldDescriptions.other_file"></span>
            </v-col>
          </v-row>
          <v-row dense class="mb-10">
            <v-col cols="12" md="6">
              <GerberFileChip
                v-if="otherFileContainer"
                :fileList="otherFileContainer"
                removeAllText="Clear"
                :allowDelete="false"
                :allowDownload="true"
                :allowDeleteAll="false"
                @download="downloadFile"
              />
              <v-file-input
                show-size
                counter
                v-model="formDut1Supporting"
                persistent-hint
                outlined
                dense
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="otherfile"
                :data-cy="`rfq-dut-support-file-input-${dutIndex}`"
                @change="
                  updateUIState({
                    key: `other_file_${dutIndex}`,
                    value: formDut1Supporting,
                  })
                "
                :rules="
                  hybridRules({
                    required: false,
                    formValue: formDut1Supporting,
                    vuexValue: null,
                  })
                "
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-10 mx-0">
            <v-col cols="12">
              <span class="subtitle-1">Additional Comments</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span v-html="dutFieldDescriptions.additional_comments"></span>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <v-textarea
                name="dut1comments"
                type="text"
                auto-grow
                flat
                outlined
                solo
                dense
                class="subtitle-1"
                v-model="formDut1Comments"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="additionalcomments"
                :data-cy="`rfq-dut-additional-comments-${dutIndex}`"
                @input="
                  updateUIState({
                    key: `additional_comments_${dutIndex}`,
                    value: formDut1Comments,
                  })
                "
                :rules="
                  hybridRules({
                    required: false,
                    formValue: formDut1Comments,
                    vuexValue: dutDetails.additional_comments,
                  })
                "
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-5 mx-0">
            <v-col cols="12">
              <span class="subtitle-1">DUT Details</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" md="6">
              <span>Select if your board is any of the following</span>
            </v-col>
          </v-row>
          <v-row dense class="mb-10">
            <v-col cols="12" md="6">
              <v-checkbox
                dense
                v-model="formDut1FlexBoard"
                label="Flex Board"
                color="secondary"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="flexboard"
                :data-cy="`rfq-dut-flex-board-${dutIndex}`"
                @change="
                  updateUIState({
                    key: `flex_board_${dutIndex}`,
                    value: formDut1FlexBoard,
                  })
                "
                :rules="
                  hybridRules({
                    required: false,
                    formValue: formDut1FlexBoard,
                    vuexValue: dutDetails.flex_board,
                  })
                "
              />
              <span v-html="dutFieldDescriptions.flex_board"></span>
              <v-checkbox
                v-model="formDut1DualSidedProbing"
                label="Dual Sided Probing"
                color="secondary"
                :disabled="!isQuoteIncomplete && !isAdmin"
                data-test="dualsidedprobing"
                :data-cy="`rfq-dut-dual-sided-probing-${dutIndex}`"
                class="mt-10"
                @change="
                  updateUIState({
                    key: `dual_sided_probing_${dutIndex}`,
                    value: formDut1DualSidedProbing,
                  })
                "
                :rules="
                  hybridRules({
                    required: false,
                    formValue: formDut1DualSidedProbing,
                    vuexValue: dutDetails.dual_sided_probing,
                  })
                "
              />
              <span v-html="dutFieldDescriptions.dual_sided_probing"></span>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row dense class="mx-10 my-5">
          <v-col cols="12" md="6">
            <v-row v-if="calledFromQuote">
              <v-btn
                x-large
                color="secondary"
                @click="
                  onSave({
                    key: 'status',
                    value: 'quoting',
                  })
                "
                :data-cy="`rfq-dut-submit-button-${dutIndex}`"
                >{{
                  (!inputValid && Object.keys(quote.fixture_design_details).length > 0 ? "Save and " : "") +
                    (isLastTab
                      ? isQuoteIncomplete
                        ? "Submit"
                        : "Continue"
                      : "Continue")
                }}</v-btn
              >
            </v-row>
            <v-row v-else>
              <v-btn x-large color="secondary" @click="onSave({})" :data-cy="`fd-dut-submit-button-${dutIndex}`">{{
                !inputValid ? "Save and Continue" : "Continue"
              }}</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <FileParserModal
      v-if="parserModal"
      ref="dut_parser"
      :dialog="parserModal"
      :parsedJsonBlob="parsedJsonBlob"
      :parsedTestPoints="parsedTestPoints"
      :parsedGuidePins="parsedGuidePins"
      :parsedPressurePins="parsedPressurePins"
      :parsedHoles="parsedHoles"
      :parsedDutData="parsedDutData"
      :parsedMissingHeaders="parsedMissingHeaders"
      :testpointLayers="testpointLayers"
      :testpointTypes="testpointTypes"
      :pcbStackup="pcbStackup"
      :isAdmin="isAdmin"
      :savedDut="testpointFile"
      :dutUnits="dutUnits"
      :dutDetails="dutDetails"
      :dutReport="dutFieldDescriptions.dut_report"
      :altiumReport="dutFieldDescriptions.altium_report"
      :experimentalFiles="dutFieldDescriptions.experimental_files"
      :savedGerbers="currentGerbers"
      @parseTestPoint="parseTestPoint"
      @saveParsedTestPoints="saveParsedTestPoints"
      @initCachedParsedData="initCachedParsedData"
      @closeModal="closeParserModal"
    />
    <FileUploadModal
      v-if="gerberModal"
      :dialog="gerberModal"
      :gerbers="currentGerbers"
      :gerberZipFile="gerberZipFile"
      :isAdmin="isAdmin"
      :dutDetails="dutDetails"
      @saveGerbers="saveGerbers"
      @closeModal="closeGerberModal"
    />
    <BaseBottomDialog
      :dialog="bottomDialog"
      :message="confirmMessage"
      :action="confirmAction"
      @removeConfirmed="removeAllFile"
      @removeDutConfirmed="removeAllDut"
      @openingGerberModal="openingGerberModal"
      @donothing="stopDialog"
    />
  </v-sheet>
</template>
<script>
import axios from "axios";
import BottomDialog from "@/mixins/BottomDialog";
export default {
  name: "DutDetailsPage",
  mixins: [BottomDialog],
  props: {
    calledFromQuote: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
      default: null,
    },
    dutIndex: {
      type: Number,
      default: null,
    },
    quote: {
      type: Object,
      default: null,
    },
    quoteTabs: {
      type: Array,
      default: null,
    },
    dutDetails: {
      type: Object,
      default: null,
    },
    dutFieldDescriptions: {
      type: Object,
      default: null,
    },
    inputValid: {
      type: Boolean,
      default: false,
    },
    previousValid: {
      type: Boolean,
      default: false,
    },
    isInitialState: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      default: null,
    },
    currentGerbers: {
      type: Array,
      default: () => [],
    },
    dutId: {
      type: Number,
      default: null,
    },
    parsedJsonBlob: {
      type: Object,
      default: null,
    },
    parsedTestPoints: {
      type: Array,
      default: null,
    },
    parsedGuidePins: {
      type: Array,
      default: null,
    },
    parsedPressurePins: {
      type: Array,
      default: null,
    },
    parsedHoles: {
      type: Array,
      default: null,
    },
    parsedDutData: {
      type: Object,
      default: null,
    },
    parsedMissingHeaders: {
      type: Array,
      default: null,
    },
    testpointLayers: {
      type: Array,
      default: null,
    },
    testpointTypes: {
      type: Array,
      default: null,
    },
    pcbStackup: {
      type: Object,
      default: null,
    },
    dutConfig: {
      type: Object,
      default: null,
    },
    dutUnits: {
      type: Array,
      default: null,
    },
    isQuoteIncomplete: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    GerberFileChip: () =>
      import("@/modules/dutconfigs/components/GerberFileChip.vue"),
    FileUploadModal: () =>
      import("@/modules/dutconfigs/components/GerberParserModal.vue"),
    FileParserModal: () => import("../components/FileParserModal.vue"),
  },
  data() {
    return {
      formDut1Reference:
        Object.keys(this.dutDetails).length > 0
          ? this.dutDetails.dut_name
          : null,
      formDut1PcbWidth:
        Object.keys(this.dutDetails).length > 0
          ? this.dutDetails.pcb_width
          : null,
      formDut1PcbHeight:
        Object.keys(this.dutDetails).length > 0
          ? this.dutDetails.pcb_height
          : null,
      formDut1PcbThickness:
        Object.keys(this.dutDetails).length > 0
          ? this.dutDetails.pcb_thickness
          : null,
      formDut1FlexBoard:
        Object.keys(this.dutDetails).length > 1
          ? this.dutDetails.flex_board
          : false,
      formDut1DualSidedProbing:
        Object.keys(this.dutDetails).length > 1
          ? this.dutDetails.dual_sided_probing
          : false,
      formDut1Units:
        Object.keys(this.dutDetails).length > 1 ? this.dutDetails.units : null,
      formDut1XOrigin:
        Object.keys(this.dutDetails).length > 1
          ? this.dutDetails.x_origin
          : null,
      formDut1YOrigin:
        Object.keys(this.dutDetails).length > 1
          ? this.dutDetails.y_origin
          : null,
      formDut1TestPoint: null,
      formDut1GerberZip: null,
      formDut1OdbZip: null,
      formDut1IpcZip: null,
      formDut1Model3D: null,
      formDut1Schematic: null,
      formDut1Supporting: null,
      formDut1Comments:
        Object.keys(this.dutDetails).length > 1
          ? this.dutDetails.additional_comments
          : null,
      gerberModal: false,
      zippedGerbers: [],
      parserModal: false,
      pcbUnitPk:
        Object.keys(this.dutDetails).length > 1 ? this.dutDetails.units : null,
      gerberPayload: null,
    };
  },
  computed: {
    testpointFile() {
      return this.dutDetails && this.dutDetails.test_point_file
        ? this.dutDetails.test_point_file
        : null;
    },
    testpointFileContainer() {
      return this.dutDetails && this.dutDetails.test_point_file
        ? [
            {
              url: this.dutDetails.test_point_file,
              file: this.dutDetails.test_point_file.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    gerberZipContainer() {
      return this.currentGerbers && this.currentGerbers.length > 0
        ? this.currentGerbers.map((element) => ({
            pk: element.pk,
            url: element.file,
            file: element.file.split("?")[0].split("/").pop(),
          }))
        : null;
    },
    unmodifiedGerberZipContainer() {
      return this.dutDetails && this.dutDetails.gerber_zip
        ? [
            {
              url: this.dutDetails.gerber_zip,
              file: this.dutDetails.gerber_zip.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    odbZipContainer() {
      return this.dutDetails && this.dutDetails.odb_zip
        ? [
            {
              url: this.dutDetails.odb_zip,
              file: this.dutDetails.odb_zip.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    ipcZipContainer() {
      return this.dutDetails && this.dutDetails.ipc_zip
        ? [
            {
              url: this.dutDetails.ipc_zip,
              file: this.dutDetails.ipc_zip.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    stepFileContainer() {
      return this.dutDetails && this.dutDetails.step_file
        ? [
            {
              url: this.dutDetails.step_file,
              file: this.dutDetails.step_file.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    schematicFileContainer() {
      return this.dutDetails && this.dutDetails.schematic_file
        ? [
            {
              url: this.dutDetails.schematic_file,
              file: this.dutDetails.schematic_file.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    otherFileContainer() {
      return this.dutDetails && this.dutDetails.other_file
        ? [
            {
              url: this.dutDetails.other_file,
              file: this.dutDetails.other_file.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    zippedGerberContainer() {
      return this.dutDetails && this.dutDetails.gerber_zip
        ? [
            {
              url: this.dutDetails.gerber_zip,
              file: this.dutDetails.gerber_zip.split("?")[0].split("/").pop(),
            },
          ]
        : null;
    },
    isLastTab() {
      return this.quoteTabs.length - 1 == this.tab;
    },
    invalidManufacturing() {
      return (
        !this.gerberZipContainer &&
        !this.unmodifiedGerberZipContainer &&
        !this.odbZipContainer &&
        !this.ipcZipContainer &&
        !this.formDut1OdbZip &&
        !this.formDut1IpcZip &&
        this.zippedGerbers.length == 0
      );
    },
    pcbUnit() {
      return this.dutUnits.find((element) => element.pk == this.pcbUnitPk)
        ?.description;
    },
    gerberZipFile() {
      return this.dutDetails ? this.dutDetails.gerber_zip : null;
    },
  },
  methods: {
    hybridRules({ required }) {
      const rules = [];
      if (required) {
        const rule = (v) => !!v || "Input is required";
        rules.push(rule);
      }
      return rules;
    },
    async onSave(payload) {
      if (this.calledFromQuote && ((!this.isQuoteIncomplete && !this.isAdmin) || Object.keys(this.quote.fixture_design_details).length == 0)) {
        this.$emit("onBackToQuoteList");
        return;
      }
      if (
        !this.$refs.form.validate() ||
        this.invalidManufacturing ||
        !this.testpointFileContainer
      ) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Please fill up all required fields."
        );
        return;
      }

      if (this.inputValid && !this.isLastTab) {
        this.onNextTab();
        return;
      }

      let inputData = new FormData();
      inputData.append("dut_name", this.formDut1Reference);
      inputData.append("pcb_thickness", this.formDut1PcbThickness);
      inputData.append("flex_board", this.formDut1FlexBoard);
      inputData.append("dual_sided_probing", this.formDut1DualSidedProbing);
      if (this.formDut1PcbWidth) {
        inputData.append("pcb_width", this.formDut1PcbWidth);
      }
      if (this.formDut1PcbHeight) {
        inputData.append("pcb_height", this.formDut1PcbHeight);
      }
      if (this.formDut1Units) {
        inputData.append("units", this.formDut1Units);
      }
      if (this.formDut1XOrigin) {
        inputData.append("x_origin", this.formDut1XOrigin);
      }
      if (this.formDut1YOrigin) {
        inputData.append("y_origin", this.formDut1YOrigin);
      }
      if (this.formDut1Comments) {
        inputData.append("additional_comments", this.formDut1Comments);
      }
      if (this.formDut1TestPoint) {
        inputData.append("test_point_file", this.formDut1TestPoint);
      }
      if (this.formDut1GerberZip) {
        inputData.append("gerber_zip", this.formDut1GerberZip);
      }
      if (this.formDut1OdbZip) {
        inputData.append("odb_zip", this.formDut1OdbZip);
      }
      if (this.formDut1IpcZip) {
        inputData.append("ipc_zip", this.formDut1IpcZip);
      }
      if (this.formDut1Model3D) {
        inputData.append("step_file", this.formDut1Model3D);
      }
      if (this.formDut1Schematic) {
        inputData.append("schematic_file", this.formDut1Schematic);
      }
      if (this.formDut1Supporting) {
        inputData.append("other_file", this.formDut1Supporting);
      }

      const actionPayload = {
        editMode: true,
        id: this.dutDetails.id,
        data: inputData,
      };

      let configData = new FormData();
      configData.append("dut_name", this.formDut1Reference);
      configData.append("pcb_thickness", this.formDut1PcbThickness);
      configData.append("pcb_x", this.formDut1PcbWidth);
      configData.append("pcb_y", this.formDut1PcbHeight);

      this.$emit("onSaveAllDutData", {
        payload: actionPayload,
        nextTab: this.isLastTab ? false : true,
        submitPayload: payload,
        dutIndex: this.dutIndex,
        blobs: this.getDutDataBlob(
          JSON.parse(this.dutDetails["dut_file_data"])
        ),
        configData,
      });
    },
    getDutDataBlob(dut_file_data) {
      if (Object.keys(dut_file_data).length == 0) {
        return {
          testpoints: [],
          guidepins: [],
          pressurepins: [],
        };
      }
      const { test_points, guide_pins, pressure_pins } = dut_file_data;
      const mapped_test_point = test_points.map((element) => ({
        project: element.project,
        dut_config: element.dut_config,
        x: element.x,
        y: element.y,
        min_spacing: element.min_spacing,
        name: element.name,
        ref: element.name,
        net: element.net,
        layer: element.layer,
        type: element.type,
        requested_receptacle_type: element.requested_receptacle_type,
        requested_probe_type: element.requested_probe_type,
      }));
      const mapped_guide_pin = guide_pins.map((element) => ({
        project: element.project,
        dut_config: element.dut_config,
        x: element.x,
        y: element.y,
        name: element.name,
      }));
      const mapped_pressure_pin = pressure_pins.map((element) => ({
        project: element.project,
        dut_config: element.dut_config,
        x: element.x,
        y: element.y,
        name: element.name,
      }));
      return {
        testpoints: mapped_test_point,
        guidepins: mapped_guide_pin,
        pressurepins: mapped_pressure_pin,
      };
    },
    onCancel() {
      this.$refs.form.reset();
      this.$emit("backToPrevious");
    },
    async onSaveDutData({ id, key, value }) {
      this.$emit("onSaveDutData", { id, key, value, index: this.dutIndex });
    },
    async onSaveDutFileData(payload) {
      this.$emit("onSaveDutFileData", payload);
      this[payload.varStr] = null;
      this.updateUIState({
        key: `${payload.key}_${this.dutIndex}`,
        value: this[payload.varStr],
      });
    },
    onNextTab() {
      this.$emit("nextTab");
    },
    onPreviousTab() {
      this.$emit("previousTab");
    },
    updateUIState(payload) {
      this.$emit("onUpdateQuoteDutDetails", payload);
    },
    updateDutName(payload) {
      this.formDut1Reference = payload;
    },
    initializeAllState() {
      this.initStateAfterSave({
        varStr: "formDut1TestPoint",
        uiStr: "test_point_file",
        value: null,
      });
      this.initStateAfterSave({
        varStr: "formDut1GerberZip",
        uiStr: "gerber_zip",
        value: null,
      });
      this.initStateAfterSave({
        varStr: "formDut1OdbZip",
        uiStr: "odb_zip",
        value: null,
      });
      this.initStateAfterSave({
        varStr: "formDut1IpcZip",
        uiStr: "ipc_zip",
        value: null,
      });
      this.initStateAfterSave({
        varStr: "formDut1Model3D",
        uiStr: "step_file",
        value: null,
      });
      this.initStateAfterSave({
        varStr: "formDut1Schematic",
        uiStr: "schematic_file",
        value: null,
      });
      this.initStateAfterSave({
        varStr: "formDut1Supporting",
        uiStr: "other_file",
        value: null,
      });
      this.removeAllZip();
      this.updateUIState({
        key: `zipped_gerbers_${this.dutIndex}`,
        value: null,
      });
    },
    initStateAfterSave({ varStr, uiStr, value }) {
      this[varStr] = null;
      this.updateUIState({
        key: `${uiStr}_${this.dutIndex}`,
        value,
      });
    },
    async saveGerbers({ actionPayload, zipped_file, withExistingGerbers = false, isBackwardCompatibility = false }) {
      this.closeGerberModal();
      console.log(actionPayload, zipped_file);
      this.zippedGerbers = actionPayload.map((element) => ({
        file: element.filename,
        gerber: element.gerber,
      }));
      if (this.calledFromQuote && this.zippedGerbers.length > 0) {
        this.updateUIState({
          key: `zipped_gerbers_${this.dutIndex}`,
          value: this.zippedGerbers,
        });
      }
      const gerberLayers = actionPayload.map((element) => ({
        file: element.filename,
        gerber: element.gerber,
        layer: element.type,
        side: element.side,
        svg: element.svg,
      }));
      const gerberData = {
        user: this.calledFromQuote ? this.quote.user : this.project.user,
        project: this.calledFromQuote
          ? Object.values(this.quote.fixture_design_details)[0]?.project_pk
          : this.project.pk,
        fixture_design: this.calledFromQuote
          ? Object.keys(this.quote.fixture_design_details)[0]
          : Object.keys(this.project.fixture_design_details)[0],
        dut_id: this.dutId,
        gerberFiles: [...this.zippedGerbers],
        gerberLayers: [...gerberLayers],
      };

      this.$emit("onSaveGerberFiles", {
        dutIndex: this.dutIndex,
        gerbers: gerberData,
        zipped_file,
        withExistingGerbers,
        isBackwardCompatibility
      });
      this.removeAllZip();
    },
    closeGerberModal() {
      this.gerberModal = false;
    },
    openGerberModal() {
      if (
        this.zippedGerbers.length > 0 ||
        (!this.isQuoteIncomplete && !this.isAdmin)
      )
        return;

      if (
        this.calledFromQuote &&
        Object.keys(this.quote.fixture_design_details).length == 0
      ) {
        this.showDialog({
          nextAction: `openingGerberModal`,
          message: `Are you sure you want to reparse this file? It will recreate a new Project, Fixture Design, and DUT Config model and link it to this Quote.`,
        });
      } else {
        this.openingGerberModal();
      }
    },
    openingGerberModal() {
      this.stopDialog();
      this.gerberModal = true;
    },
    clearUploadedGerbers() {
      this.removeAllZip();
      this.updateUIState({
        key: `zipped_gerbers_${this.dutIndex}`,
        value: null,
      });
    },
    removeAllZip() {
      this.zippedGerbers.splice(0);
    },
    removeAllFile() {
      this.$emit("deleteCurrentGerbers", this.dutIndex);
      this.stopDialog();
    },
    removeAllDut() {
      this.$emit("deleteCurrentDut", this.dutIndex);
      this.stopDialog();
    },
    downloadFile(payload) {
      this.$store.dispatch("ui/loading", "Downloading, one moment please");
      this.$emit("downloadGerber", payload);
      this.$store.dispatch("ui/loading", null);
    },
    async openParserModal() {
      if (!this.isQuoteIncomplete && !this.isAdmin) return;
      if (this.calledFromQuote && !this.dutId) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Please link the Quote to a Project before you proceed."
        );
        return;
      }
      if (this.testpointFile) {
        try {
          const response = await axios.get(this.testpointFile);
          console.log("Google File", response);
          this.parseTestPoint(
            new File([response.data], this.testpointFile.split("?")[0].split("/").pop(), {
              type: "text/csv",
            })
          );
        } catch (err) {
          this.$store.dispatch("ui/snackLink", {
            message1: "Something broke internally, contact ",
            message2: " for help.",
            email: true,
            link: {
              url: "mailto:support@fixturfab.com",
              text: "support@fixturfab.com",
            },
          });
        }
      } else {
        this.parserModal = true;
      }
    },
    closeParserModal() {
      this.parserModal = false;
    },
    parseTestPoint(payload) {
      let parseData = new FormData();
      parseData.append("testpoint_file", payload);
      parseData.append(
        "project_pk",
        this.calledFromQuote
          ? Object.values(this.quote.fixture_design_details)[0]?.project_pk
          : this.project.pk
      );
      const actionPayload = {
        data: parseData,
        dut_id: this.calledFromQuote ? this.dutId : this.dutConfig.pk,
      };
      this.$emit("parseTestPoint", {
        payload: actionPayload,
        dutId: this.dutIndex,
      });
    },
    async saveParsedTestPoints({
      testpoint_file,
      dut_settings,
      parsed_json_blob,
    }) {
      console.log("PAYLOAD", testpoint_file, dut_settings, parsed_json_blob);
      this.formDut1TestPoint = testpoint_file;
      this.formDut1PcbHeight = dut_settings.dut_length;
      this.formDut1PcbWidth = dut_settings.dut_width;
      this.formDut1PcbThickness = dut_settings.dut_height;
      this.formDut1Units = dut_settings.units;
      this.formDut1XOrigin = dut_settings.x_origin;
      this.formDut1YOrigin = dut_settings.y_origin;

      let detailsData = new FormData();
      detailsData.append("pcb_thickness", this.formDut1PcbThickness);
      detailsData.append("pcb_width", this.formDut1PcbWidth);
      detailsData.append("pcb_height", this.formDut1PcbHeight);
      detailsData.append("units", this.formDut1Units);
      detailsData.append("x_origin", this.formDut1XOrigin);
      detailsData.append("y_origin", this.formDut1YOrigin);
      detailsData.append("dut_file_data", JSON.stringify(parsed_json_blob));
      if (this.formDut1TestPoint) {
        detailsData.append("test_point_file", this.formDut1TestPoint);
      }
      const detailData = {
        editMode: true,
        id: this.dutDetails.id,
        data: detailsData,
      };
      console.log("Detail Data", detailData);
      this.updateUIState({
        key: `test_point_file_${this.dutIndex}`,
        value: testpoint_file,
      });
      this.updateUIState({
        key: `pcb_height_${this.dutIndex}`,
        value: this.formDut1PcbHeight,
      });
      this.updateUIState({
        key: `pcb_width_${this.dutIndex}`,
        value: this.formDut1PcbWidth,
      });
      this.updateUIState({
        key: `pcb_thickness_${this.dutIndex}`,
        value: this.formDut1PcbThickness,
      });
      this.$emit("onSaveAllDutFromModal", {
        payload: detailData,
        dutIndex: this.dutIndex,
      });
    },
    clearUploadedTestPoint() {
      this.initStateAfterSave({
        varStr: "formDut1TestPoint",
        uiStr: "test_point_file",
        value: null,
      });
      this.formDut1PcbHeight = this.formDut1PcbWidth = this.formDut1PcbThickness = null;
    },
    updateConfig2d() {
      if (!this.parserModal) {
        this.parserModal = true;
        setTimeout(() => {
          this.$refs["dut_parser"] && this.$refs["dut_parser"].initConfig2d();
        }, 1);
      } else {
        this.$refs["dut_parser"] && this.$refs["dut_parser"].initConfig2d();
      }
    },
    initTestPointFile() {
      this.$refs["dut_parser"] && this.$refs["dut_parser"].initTestPointFile();
    },
    initCachedParsedData() {
      this.$store.commit("quotes/INIT_PARSED_FILES");
    },
    initDutFields() {
      this.formDut1PcbWidth = this.dutDetails.pcb_width;
      this.formDut1PcbHeight = this.dutDetails.pcb_height;
      this.formDut1PcbThickness = this.dutDetails.pcb_thickness;
    },
    onInputString() {
      return "if (this.value > 2147483647) this.value = 2147483647; if (this.value < 0) this.value = 0;"
    }
  },
  mounted() {
    this.$refs.form.validate();
    this.$emit("scrollToTop");
  },
};
</script>
<style scoped>
.borderError {
  border-color: red;
}
.currentColor {
  border-color: #788b8d;
}
.disabledColor {
  border-color: #c5c5c5;
}
</style>
