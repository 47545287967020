var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{attrs:{"flat":""}},[_c('v-card',{staticClass:"ma-1",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-10"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"pa-0 mt-4 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1 mb-0"},[_vm._v("DUT Name *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.dut_name)}})])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"dut1Reference","type":"text","autocomplete":"off","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"dutname","data-cy":`rfq-dut-dut-name-${_vm.dutIndex}`,"rules":_vm.hybridRules({
                  required: true,
                  formValue: _vm.formDut1Reference,
                  vuexValue: _vm.dutDetails.dut_name,
                })},on:{"input":function($event){return _vm.updateUIState({
                  key: `dut_name_${_vm.dutIndex}`,
                  value: _vm.formDut1Reference,
                })}},model:{value:(_vm.formDut1Reference),callback:function ($$v) {_vm.formDut1Reference=$$v},expression:"formDut1Reference"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-sheet',{staticClass:"px-5",class:_vm.invalidManufacturing ? 'borderError' : 'currentColor',attrs:{"rounded":"","light":"","outlined":""}},[_c('v-row',{staticClass:"pa-0 mt-5 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Manufacturing Files *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.invalidManufacturing)?_c('v-alert',{staticClass:"ma-0 subtitle-2",attrs:{"color":"error","dense":"","outlined":"","type":"info"}},[_vm._v("Upload a zipped folder of manufacturing files. Gerbers, ODB++, or IPC2581.")]):_vm._e()],1)],1),_c('v-row',{staticClass:"pa-0 mt-5 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Gerber Zip")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.gerber_zip)}})])],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[(
                        _vm.unmodifiedGerberZipContainer &&
                          _vm.zippedGerbers.length === 0
                      )?_c('GerberFileChip',{staticClass:"ml-3",attrs:{"editMode":_vm.editMode,"fileList":_vm.unmodifiedGerberZipContainer,"prefixText":"Uploaded Gerber Zip","allowDelete":false,"allowDownload":true,"allowDeleteAll":false,"color":"secondary"},on:{"download":_vm.downloadFile}}):_vm._e()],1),(_vm.gerberZipContainer && _vm.zippedGerbers.length === 0)?_c('GerberFileChip',{staticClass:"ml-3",attrs:{"editMode":_vm.editMode,"fileList":_vm.gerberZipContainer,"prefixText":"Saved Layer File","removeAllText":"Clear","allowDelete":false,"allowDownload":true,"allowDeleteAll":(_vm.isQuoteIncomplete || _vm.isAdmin) && (_vm.calledFromQuote ? Object.keys(_vm.quote.fixture_design_details).length > 0 : true) ? true : false},on:{"download":_vm.downloadFile,"removeAll":function($event){return _vm.showDialog({
                        nextAction: `removeConfirmed`,
                        message: `Are you sure you want to remove the Gerber files and data associated with it?`,
                      })}}}):_vm._e(),(
                      _vm.zippedGerberContainer &&
                        _vm.zippedGerbers.length === 0 &&
                        _vm.gerberZipContainer
                    )?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning","text-color":"white","small":""},on:{"click":function($event){return _vm.downloadFile(_vm.zippedGerberContainer[0])}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-alert-circle-outline ")]),_vm._v(" "+_vm._s(`Download All Gerbers`)+" ")],1):_vm._e(),(
                      _vm.calledFromQuote &&
                        Object.keys(_vm.quote.fixture_design_details).length == 0
                    )?_c('v-alert',{staticClass:"subtitle-2 px-5",attrs:{"border":"left","color":"red","type":"info"}},[_vm._v(" This Quote's Project was unlinked from it. Re-upload the Quote's Gerber zip file to re-parse gerber layers and save them to a new Fixture Design and DUT Config. ")]):_vm._e(),_c('v-sheet',{staticClass:"ml-8 cursor-text py-2",class:!_vm.isQuoteIncomplete && !_vm.isAdmin
                        ? 'disabledColor'
                        : 'currentColor',attrs:{"outlined":"","rounded":"","data-cy":`rfq-dut-open-gerber-modal-${_vm.dutIndex}`},on:{"click":function($event){$event.stopPropagation();return _vm.openGerberModal.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"ml-n8"},[_vm._v("mdi-paperclip")]),(_vm.zippedGerbers.length === 0)?_c('span',{staticClass:"ml-5 subtitle-1",class:(!_vm.isInitialState && !_vm.isAdmin) ||
                            (!_vm.isQuoteIncomplete && !_vm.isAdmin)
                              ? 'grey--text'
                              : ''},[_vm._v(" Click here to upload zipped Gerber Files * ")]):_c('span',[_c('GerberFileChip',{staticClass:"ml-5",attrs:{"editMode":_vm.editMode,"fileList":[_vm.zippedGerbers[0]]}}),(_vm.zippedGerbers.length > 1)?_c('span',{staticClass:"subtitle-2 grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.zippedGerbers.length - 1)+" File(s) ")]):_vm._e(),_c('span',[_c('v-btn',{attrs:{"small":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.clearUploadedGerbers.apply(null, arguments)}}},[_vm._v("Clear")])],1)],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"pa-0 mt-5 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("ODB++ Zip")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.odb_zip)}})])],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.odbZipContainer)?_c('GerberFileChip',{attrs:{"fileList":_vm.odbZipContainer,"removeAllText":"Clear","allowDelete":false,"allowDownload":true,"allowDeleteAll":false},on:{"download":_vm.downloadFile}}):_vm._e(),_c('v-file-input',{attrs:{"show-size":"","counter":"","accept":".zip, .rar, .tgz","hint":"File types: .zip, .rar, .tgz","persistent-hint":"","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"odbzipfile","data-cy":`rfq-dut-odb-file-input-${_vm.dutIndex}`,"rules":_vm.hybridRules({
                        required: false,
                        formValue: _vm.formDut1OdbZip,
                        vuexValue: null,
                      })},on:{"change":function($event){return _vm.updateUIState({
                        key: `odb_zip_${_vm.dutIndex}`,
                        value: _vm.formDut1OdbZip,
                      })}},scopedSlots:_vm._u([{key:"selection",fn:function({ text }){return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.formDut1OdbZip),callback:function ($$v) {_vm.formDut1OdbZip=$$v},expression:"formDut1OdbZip"}})],1)],1),_c('v-row',{staticClass:"pa-0 mt-5 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("IPC2581 Zip")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.ipc_zip)}})])],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.ipcZipContainer)?_c('GerberFileChip',{attrs:{"fileList":_vm.ipcZipContainer,"removeAllText":"Clear","allowDelete":false,"allowDownload":true,"allowDeleteAll":false},on:{"download":_vm.downloadFile}}):_vm._e(),_c('v-file-input',{attrs:{"show-size":"","counter":"","accept":".zip, .rar, .tgz","hint":"File types: .zip, .rar, .tgz","persistent-hint":"","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"ipczipfiles","data-cy":`rfq-dut-ipc-file-input-${_vm.dutIndex}`,"rules":_vm.hybridRules({
                        required: false,
                        formValue: _vm.formDut1IpcZip,
                        vuexValue: null,
                      })},on:{"change":function($event){return _vm.updateUIState({
                        key: `ipc_zip_${_vm.dutIndex}`,
                        value: _vm.formDut1IpcZip,
                      })}},scopedSlots:_vm._u([{key:"selection",fn:function({ text }){return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.formDut1IpcZip),callback:function ($$v) {_vm.formDut1IpcZip=$$v},expression:"formDut1IpcZip"}})],1)],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-sheet',{staticClass:"px-5 pb-5 my-5",class:!_vm.formDut1TestPoint && !_vm.testpointFileContainer
                  ? 'borderError'
                  : 'currentColor',attrs:{"rounded":"","light":"","outlined":""}},[_c('v-row',{staticClass:"pa-0 mt-5 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Device Under Test (DUT) Report *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.test_point_file)}})])],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.testpointFileContainer)?_c('GerberFileChip',{attrs:{"fileList":_vm.testpointFileContainer,"removeAllText":"Clear","allowDelete":false,"allowDownload":true,"allowDeleteAll":_vm.isQuoteIncomplete || _vm.isAdmin ? true : false},on:{"download":_vm.downloadFile,"removeAll":function($event){return _vm.showDialog({
                        nextAction: `removeDutConfirmed`,
                        message: `Are you sure you want to remove the DUT File and data associated with it?`,
                      })}}}):_vm._e(),_c('v-sheet',{staticClass:"ml-8 py-2 cursor-text",class:!_vm.testpointFileContainer && !_vm.formDut1TestPoint
                        ? 'borderError'
                        : !_vm.isQuoteIncomplete && !_vm.isAdmin
                        ? 'disabledColor'
                        : 'currentColor',attrs:{"outlined":"","rounded":"","data-cy":`rfq-dut-open-dut-modal-${_vm.dutIndex}`},on:{"click":function($event){$event.stopPropagation();return _vm.openParserModal.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"ml-n8",attrs:{"color":!_vm.testpointFileContainer && !_vm.formDut1TestPoint
                              ? 'error'
                              : ''}},[_vm._v("mdi-paperclip")]),(!_vm.formDut1TestPoint)?_c('span',{staticClass:"ml-5 subtitle-1",class:!_vm.testpointFileContainer && !_vm.formDut1TestPoint
                              ? 'error--text'
                              : (!_vm.isInitialState && !_vm.isAdmin) ||
                                (!_vm.isQuoteIncomplete && !_vm.isAdmin)
                              ? 'grey--text'
                              : ''},[_vm._v(" Click here to upload Test Point file * ")]):_c('span',[_c('GerberFileChip',{staticClass:"ml-5",attrs:{"prefixText":"Uploaded File","editMode":_vm.editMode,"fileList":[{ file: _vm.formDut1TestPoint.name }],"allowDelete":false,"allowDownload":false,"allowDeleteAll":false},on:{"removeAll":_vm.removeAllZip}}),_c('span',[_c('v-btn',{attrs:{"small":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.clearUploadedTestPoint.apply(null, arguments)}}},[_vm._v("Clear")])],1)],1)],1)],1)],1)],1)],1),(!_vm.testpointFileContainer && !_vm.formDut1TestPoint)?_c('v-row',{staticClass:"pa-0 mt-5 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("DUT dimensions will be populated after DUT File is uploaded.")])])],1):_vm._e(),_c('v-row',{staticClass:"pa-0 mt-2 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1",class:!_vm.formDut1TestPoint ? 'grey--text' : ''},[_vm._v("PCB Width")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{class:!_vm.formDut1TestPoint ? 'grey--text' : ''},[_vm._v(_vm._s(_vm.pcbUnit))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"dut1PcbWidth","type":"number","outlined":"","dense":"","data-test":"pcbwidth","disabled":"","onInput":_vm.onInputString(),"rules":_vm.hybridRules({
                                required: false,
                                formValue: _vm.formDut1PcbWidth,
                                vuexValue: _vm.dutDetails.pcb_width,
                              })},on:{"input":function($event){return _vm.updateUIState({
                                key: `pcb_width_${_vm.dutIndex}`,
                                value: _vm.formDut1PcbWidth,
                              })}},model:{value:(_vm.formDut1PcbWidth),callback:function ($$v) {_vm.formDut1PcbWidth=$$v},expression:"formDut1PcbWidth"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1",class:!_vm.formDut1TestPoint ? 'grey--text' : ''},[_vm._v("PCB Height")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{class:!_vm.formDut1TestPoint ? 'grey--text' : ''},[_vm._v(_vm._s(_vm.pcbUnit))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"dut1PcbHeight","type":"number","outlined":"","dense":"","data-test":"pcbheight","onInput":_vm.onInputString(),"disabled":"","rules":_vm.hybridRules({
                                required: false,
                                formValue: _vm.formDut1PcbHeight,
                                vuexValue: _vm.dutDetails.pcb_height,
                              })},on:{"input":function($event){return _vm.updateUIState({
                                key: `pcb_height_${_vm.dutIndex}`,
                                value: _vm.formDut1PcbHeight,
                              })}},model:{value:(_vm.formDut1PcbHeight),callback:function ($$v) {_vm.formDut1PcbHeight=$$v},expression:"formDut1PcbHeight"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1",class:!_vm.formDut1TestPoint ? 'grey--text' : ''},[_vm._v("PCB Thickness")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{class:!_vm.formDut1TestPoint ? 'grey--text' : ''},[_vm._v(_vm._s(_vm.pcbUnit))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"dut1PcbThickness","type":"number","outlined":"","dense":"","data-test":"pcbthickness","disabled":"","onInput":_vm.onInputString(),"rules":_vm.hybridRules({
                                required: false,
                                formValue: _vm.formDut1PcbThickness,
                                vuexValue: _vm.dutDetails.pcb_thickness,
                              })},on:{"input":function($event){return _vm.updateUIState({
                                key: `pcb_thickness_${_vm.dutIndex}`,
                                value: _vm.formDut1PcbThickness,
                              })}},model:{value:(_vm.formDut1PcbThickness),callback:function ($$v) {_vm.formDut1PcbThickness=$$v},expression:"formDut1PcbThickness"}})],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"pa-0 mt-5 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("PCBA 3D Model *")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.step_file)}})])],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.stepFileContainer)?_c('GerberFileChip',{attrs:{"fileList":_vm.stepFileContainer,"removeAllText":"Clear","allowDelete":false,"allowDownload":true,"allowDeleteAll":false},on:{"download":_vm.downloadFile}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('v-file-input',{attrs:{"show-size":"","counter":"","accept":".step, .stp","hint":"File types: .step, .stp","persistent-hint":"","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"stepfile","data-cy":`rfq-dut-step-file-input-${_vm.dutIndex}`,"rules":_vm.hybridRules({
                        required: !_vm.stepFileContainer,
                        formValue: _vm.formDut1Model3D,
                        vuexValue: null,
                      })},on:{"change":function($event){return _vm.updateUIState({
                        key: `step_file_${_vm.dutIndex}`,
                        value: _vm.formDut1Model3D,
                      })}},scopedSlots:_vm._u([{key:"selection",fn:function({ text }){return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.formDut1Model3D),callback:function ($$v) {_vm.formDut1Model3D=$$v},expression:"formDut1Model3D"}})],1)]}}])},[_c('span',[_vm._v("Accepted file types: .step, .stp")])])],1)],1),_c('v-row',{staticClass:"pa-0 mt-10 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("PCB Schematic "+_vm._s(_vm.quote.test_instrumentation_selection_and_integration || _vm.quote.test_software_development ? "*" : ""))])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.schematic_file)}})])],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.schematicFileContainer)?_c('GerberFileChip',{attrs:{"fileList":_vm.schematicFileContainer,"removeAllText":"Clear","allowDelete":false,"allowDownload":true,"allowDeleteAll":false},on:{"download":_vm.downloadFile}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('v-file-input',{attrs:{"show-size":"","counter":"","accept":".pdf","hint":"File type: .pdf","persistent-hint":"","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"schematicfile","data-cy":`rfq-dut-schematic-file-input-${_vm.dutIndex}`,"rules":_vm.hybridRules({
                        required:
                          (_vm.quote.test_instrumentation_selection_and_integration ||
                            _vm.quote.test_software_development) &&
                          !_vm.schematicFileContainer,
                        formValue: _vm.formDut1Schematic,
                        vuexValue: null,
                      })},on:{"change":function($event){return _vm.updateUIState({
                        key: `schematic_file_${_vm.dutIndex}`,
                        value: _vm.formDut1Schematic,
                      })}},scopedSlots:_vm._u([{key:"selection",fn:function({ text }){return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.formDut1Schematic),callback:function ($$v) {_vm.formDut1Schematic=$$v},expression:"formDut1Schematic"}})],1)]}}])},[_c('span',[_vm._v("Accepted file types: .pdf")])])],1)],1),_c('v-row',{staticClass:"pa-0 mt-5 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Other Supporting Files")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.other_file)}})])],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.otherFileContainer)?_c('GerberFileChip',{attrs:{"fileList":_vm.otherFileContainer,"removeAllText":"Clear","allowDelete":false,"allowDownload":true,"allowDeleteAll":false},on:{"download":_vm.downloadFile}}):_vm._e(),_c('v-file-input',{attrs:{"show-size":"","counter":"","persistent-hint":"","outlined":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"otherfile","data-cy":`rfq-dut-support-file-input-${_vm.dutIndex}`,"rules":_vm.hybridRules({
                  required: false,
                  formValue: _vm.formDut1Supporting,
                  vuexValue: null,
                })},on:{"change":function($event){return _vm.updateUIState({
                  key: `other_file_${_vm.dutIndex}`,
                  value: _vm.formDut1Supporting,
                })}},scopedSlots:_vm._u([{key:"selection",fn:function({ text }){return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.formDut1Supporting),callback:function ($$v) {_vm.formDut1Supporting=$$v},expression:"formDut1Supporting"}})],1)],1),_c('v-row',{staticClass:"pa-0 mt-10 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Additional Comments")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.additional_comments)}})])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{staticClass:"subtitle-1",attrs:{"name":"dut1comments","type":"text","auto-grow":"","flat":"","outlined":"","solo":"","dense":"","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"additionalcomments","data-cy":`rfq-dut-additional-comments-${_vm.dutIndex}`,"rules":_vm.hybridRules({
                  required: false,
                  formValue: _vm.formDut1Comments,
                  vuexValue: _vm.dutDetails.additional_comments,
                })},on:{"input":function($event){return _vm.updateUIState({
                  key: `additional_comments_${_vm.dutIndex}`,
                  value: _vm.formDut1Comments,
                })}},model:{value:(_vm.formDut1Comments),callback:function ($$v) {_vm.formDut1Comments=$$v},expression:"formDut1Comments"}})],1)],1),_c('v-row',{staticClass:"pa-0 mt-5 mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("DUT Details")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',[_vm._v("Select if your board is any of the following")])])],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"dense":"","label":"Flex Board","color":"secondary","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"flexboard","data-cy":`rfq-dut-flex-board-${_vm.dutIndex}`,"rules":_vm.hybridRules({
                  required: false,
                  formValue: _vm.formDut1FlexBoard,
                  vuexValue: _vm.dutDetails.flex_board,
                })},on:{"change":function($event){return _vm.updateUIState({
                  key: `flex_board_${_vm.dutIndex}`,
                  value: _vm.formDut1FlexBoard,
                })}},model:{value:(_vm.formDut1FlexBoard),callback:function ($$v) {_vm.formDut1FlexBoard=$$v},expression:"formDut1FlexBoard"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.flex_board)}}),_c('v-checkbox',{staticClass:"mt-10",attrs:{"label":"Dual Sided Probing","color":"secondary","disabled":!_vm.isQuoteIncomplete && !_vm.isAdmin,"data-test":"dualsidedprobing","data-cy":`rfq-dut-dual-sided-probing-${_vm.dutIndex}`,"rules":_vm.hybridRules({
                  required: false,
                  formValue: _vm.formDut1DualSidedProbing,
                  vuexValue: _vm.dutDetails.dual_sided_probing,
                })},on:{"change":function($event){return _vm.updateUIState({
                  key: `dual_sided_probing_${_vm.dutIndex}`,
                  value: _vm.formDut1DualSidedProbing,
                })}},model:{value:(_vm.formDut1DualSidedProbing),callback:function ($$v) {_vm.formDut1DualSidedProbing=$$v},expression:"formDut1DualSidedProbing"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.dutFieldDescriptions.dual_sided_probing)}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"mx-10 my-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.calledFromQuote)?_c('v-row',[_c('v-btn',{attrs:{"x-large":"","color":"secondary","data-cy":`rfq-dut-submit-button-${_vm.dutIndex}`},on:{"click":function($event){return _vm.onSave({
                  key: 'status',
                  value: 'quoting',
                })}}},[_vm._v(_vm._s((!_vm.inputValid && Object.keys(_vm.quote.fixture_design_details).length > 0 ? "Save and " : "") + (_vm.isLastTab ? _vm.isQuoteIncomplete ? "Submit" : "Continue" : "Continue")))])],1):_c('v-row',[_c('v-btn',{attrs:{"x-large":"","color":"secondary","data-cy":`fd-dut-submit-button-${_vm.dutIndex}`},on:{"click":function($event){return _vm.onSave({})}}},[_vm._v(_vm._s(!_vm.inputValid ? "Save and Continue" : "Continue"))])],1)],1)],1)],1)],1),(_vm.parserModal)?_c('FileParserModal',{ref:"dut_parser",attrs:{"dialog":_vm.parserModal,"parsedJsonBlob":_vm.parsedJsonBlob,"parsedTestPoints":_vm.parsedTestPoints,"parsedGuidePins":_vm.parsedGuidePins,"parsedPressurePins":_vm.parsedPressurePins,"parsedHoles":_vm.parsedHoles,"parsedDutData":_vm.parsedDutData,"parsedMissingHeaders":_vm.parsedMissingHeaders,"testpointLayers":_vm.testpointLayers,"testpointTypes":_vm.testpointTypes,"pcbStackup":_vm.pcbStackup,"isAdmin":_vm.isAdmin,"savedDut":_vm.testpointFile,"dutUnits":_vm.dutUnits,"dutDetails":_vm.dutDetails,"dutReport":_vm.dutFieldDescriptions.dut_report,"altiumReport":_vm.dutFieldDescriptions.altium_report,"experimentalFiles":_vm.dutFieldDescriptions.experimental_files,"savedGerbers":_vm.currentGerbers},on:{"parseTestPoint":_vm.parseTestPoint,"saveParsedTestPoints":_vm.saveParsedTestPoints,"initCachedParsedData":_vm.initCachedParsedData,"closeModal":_vm.closeParserModal}}):_vm._e(),(_vm.gerberModal)?_c('FileUploadModal',{attrs:{"dialog":_vm.gerberModal,"gerbers":_vm.currentGerbers,"gerberZipFile":_vm.gerberZipFile,"isAdmin":_vm.isAdmin,"dutDetails":_vm.dutDetails},on:{"saveGerbers":_vm.saveGerbers,"closeModal":_vm.closeGerberModal}}):_vm._e(),_c('BaseBottomDialog',{attrs:{"dialog":_vm.bottomDialog,"message":_vm.confirmMessage,"action":_vm.confirmAction},on:{"removeConfirmed":_vm.removeAllFile,"removeDutConfirmed":_vm.removeAllDut,"openingGerberModal":_vm.openingGerberModal,"donothing":_vm.stopDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }